import { AppThunk } from "../store";
import { setConsents } from "../consents/consents-actions";
import { toggleLoadingSpinner } from "../loading-spinner/loading-spinner-actions";
import { setErrorMessage } from "../errors/errors-actions";
import {
    getConsentsByCustomerId,
    updateCustomerConsentStatus,
} from "../../api/consent-management";
import { successfulResponseStatusCodes } from "../../utils/constants";
import { obtainErrorMessage } from "../../utils/error-message-provider";
import { IConsentStatus } from "../../redux/consents/consents-types";
import {
    getConsentsByGuestTenantId,
    updateGuestTenantConsentStatus,
} from "../../api/guest-tenant-consent-management";

export const getConsentsByCustomer =
    (customerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data, statusCode } = await getConsentsByCustomerId(customerId);
        if (successfulResponseStatusCodes.includes(statusCode)) {
            dispatch(setConsents(data));
        } else {
            dispatch(
                setErrorMessage(
                    obtainErrorMessage(JSON.stringify(data), statusCode)
                )
            );
        }
        dispatch(toggleLoadingSpinner(false));
    };

export const getConsentsByGuestTenant =
    (guestTenantId: string): AppThunk =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data, statusCode } = await getConsentsByGuestTenantId(
            guestTenantId
        );
        if (successfulResponseStatusCodes.includes(statusCode)) {
            dispatch(setConsents(data));
        } else {
            dispatch(
                setErrorMessage(
                    obtainErrorMessage(JSON.stringify(data), statusCode)
                )
            );
        }
        dispatch(toggleLoadingSpinner(false));
    };

export const updateCustomerConsent =
    (
        customerId: string,
        consentId: string,
        consentStatus: IConsentStatus
    ): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        const { data } = await updateCustomerConsentStatus(
            customerId,
            consentId,
            consentStatus
        );
        dispatch(toggleLoadingSpinner(false));
        return data;
    };

export const updateGuestTenantConsent =
    (
        guestTenantId: string,
        consentId: string,
        consentStatus: IConsentStatus
    ): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        const { data } = await updateGuestTenantConsentStatus(
            guestTenantId,
            consentId,
            consentStatus
        );
        dispatch(toggleLoadingSpinner(false));
        return data;
    };

import React from "react";
import { template } from "lodash";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const AzurePortalLink: React.FC = () => {
    const { t } = useTranslation();
    const linkTemplate = template(t("components.azurePortalLink.linkTemplate"));
    return (
        <div>
            {parse(
                linkTemplate({
                    link: "https://portal.azure.com/#blade/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/AllApps/",
                })
            )}
        </div>
    );
};

export default AzurePortalLink;

import React from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "../../../components/Paragraph/Paragraph";
import parse from "html-react-parser";
import stepImage from "../../../assets/images/instructionSteps/4.jpg";
import InstructionImage from "../../../components/InstructionImage/InstructionImage";
import { template } from "lodash";

interface IComponentProps {
    accountUserName: string;
}

const FourthPage: React.FC<IComponentProps> = ({
    accountUserName,
}: IComponentProps) => {
    const { t } = useTranslation();
    return (
        <Paragraph title={t("pages.instructions.fourthPage.paragraphTitle")}>
            {parse(
                template(
                    t(
                        "pages.instructions.fourthPage.paragraphFirstPartDescriptionTemplate"
                    )
                )({
                    userName: accountUserName,
                })
            )}
            <InstructionImage imageSource={stepImage} />
            <div style={{ marginBottom: "15px" }}>
                {t(
                    "pages.instructions.fourthPage.paragraphSecondPartDescriptionText"
                )}
            </div>
        </Paragraph>
    );
};

export default FourthPage;

import React from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { routePath } from "../../../utils/routes";

interface IComponentProps extends RouteProps {
    isAllowed: boolean;
}

const ProtectedRoute: React.FC<IComponentProps> = (props: IComponentProps) =>
    props.isAllowed ? (
        <Route {...props}></Route>
    ) : (
        <Redirect to={routePath.root}></Redirect>
    );

export default ProtectedRoute;

import React from "react";
import { Redirect } from "react-router-dom";
import { FlexboxGrid } from "rsuite";
import { routePath } from "../../utils/routes";
import { useTranslation } from "react-i18next";
import { template } from "lodash";
import { logoutCurrentUser } from "../../utils/auth-provider";
import css from "./Instructions.module.css";

interface IComponentProps {
    isAdministratorAccount: boolean;
    accountName: string;
}

const Instructions: React.FC<IComponentProps> = ({
    isAdministratorAccount,
    accountName,
}: IComponentProps) => {
    const { t } = useTranslation();
    return isAdministratorAccount ? (
        <Redirect to={routePath.wizard} push={true}></Redirect>
    ) : (
        <FlexboxGrid justify="center" className={css.pageContainer}>
            <FlexboxGrid.Item colspan={13}>
                <div className={css.welcomeMessageContainer}>
                    {template(t("pages.instructions.welcomeMessageTemplate"))({
                        userName: accountName,
                    })}
                </div>
                <div className={css.pageDescriptionMessageContainer}>
                    {t("pages.instructions.userPageDescriptionMessageTemplate")}
                </div>
                <div className={css.logOutMessageContainer}>
                    <a
                        onClick={() => {
                            logoutCurrentUser();
                        }}
                    >
                        {t("pages.instructions.logOutButtonText")}
                    </a>
                </div>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};

export default Instructions;

import {
    SET_CONSENTS,
    SET_CONSENTS_ASSOCIATION_TYPE,
    ISetConsents,
    ISetConsentAssociationType,
    IConsent,
    ConsentAssociationType,
} from "./consents-types";

export const setConsents = (consents: IConsent[]): ISetConsents => ({
    type: SET_CONSENTS,
    consents,
});

export const setConsentAssociationType = (
    consentAssociationType: ConsentAssociationType
): ISetConsentAssociationType => ({
    type: SET_CONSENTS_ASSOCIATION_TYPE,
    consentAssociationType,
});

import update from "immutability-helper";
import { Actions, IState, SET_GUEST_TENANT_ID } from "./guest-tenant-types";

const initialState: IState = {
    guestTenantId: "",
};

const guestTenantReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_GUEST_TENANT_ID:
            return update(state, {
                guestTenantId: { $set: action.guestTenantId },
            });
        default:
            return state;
    }
};

export default guestTenantReducer;

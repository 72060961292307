export const SET_GUEST_TENANT_ID = "guestTenantReducer/SET_GUEST_TENANT_ID";

export interface IState {
    guestTenantId: string;
}

export interface ISetGuestTenantId {
    type: typeof SET_GUEST_TENANT_ID;
    guestTenantId: string;
}

export type Actions = ISetGuestTenantId;

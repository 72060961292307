import React from "react";
import { routePath } from "../../../utils/routes";
import { IConsentState } from "../../../redux/consents/consents-types";
import { ContainerProps } from "rsuite";
interface IComponentProps extends ContainerProps {
    consentId: string;
    clientId: string;
    isEnabled: boolean;
    tenantId: string;
    consentCustomerId: string;
    guestTenantId: string;
}

const AdminConsentLink: React.FC<IComponentProps> = ({
    children,
    consentId,
    clientId,
    isEnabled,
    tenantId,
    consentCustomerId,
    guestTenantId,
}: IComponentProps) => {
    const consentResponseRedirectUrl = `${String(
        process.env.REACT_APP_BASE_URI
    )}${routePath.consentResponse}`;
    const state = JSON.stringify({
        consentId: consentId,
        clientId: clientId,
        customerId: consentCustomerId,
        guestTenantId: guestTenantId,
    } as IConsentState);
    const link =
        tenantId && consentCustomerId
            ? `https://login.microsoftonline.com/${tenantId}/v2.0/adminconsent?client_id=${clientId}&redirect_uri=${consentResponseRedirectUrl}&scope=.default&state=${state}`
            : "";
    return isEnabled ? <a href={link}>{children}</a> : <span>{children}</span>;
};

export default AdminConsentLink;

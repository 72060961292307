import React from "react";
import { IInstruction } from "./types/wizard-types";
import FirstPageContainer from "../pages/Instructions/FirstPage/FirstPageContainer";
import SecondPage from "../pages/Instructions/SecondPage/SecondPage";
import ThirdPage from "../pages/Instructions/ThirdPage/ThirdPage";
import FourthPageContainer from "../pages/Instructions/FourthPage/FourthPageContainer";
import i18n from "i18next";

export const getInstructions = (): IInstruction[] => [
    {
        description: i18n.t("instructions.descriptions.first"),
        page: <FirstPageContainer />,
    },
    {
        description: i18n.t("instructions.descriptions.second"),
        page: <SecondPage />,
    },
    {
        description: i18n.t("instructions.descriptions.third"),
        page: <ThirdPage />,
    },
    {
        description: i18n.t("instructions.descriptions.fourth"),
        page: <FourthPageContainer />,
    },
];

export const SET_ERROR_MESSAGE = "errorsReducer/SET_ERROR_MESSAGE";

export interface IState {
    errorMessage: string;
}

export interface ISetErrorMessage {
    type: typeof SET_ERROR_MESSAGE;
    errorMessage: string;
}

export type Actions = ISetErrorMessage;

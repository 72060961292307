import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { authProvider } from "./utils/auth-provider";
import AuthenticationContainer from "./components/Authentication/AuthenticationContainer";
import LayoutContainer from "./components/Layout/LayoutContainer";
import RouterContainer from "./components/Router/RouterContainer";
import store from "./redux/store";
import "./themes/styles.scss";
import { Provider } from "react-redux";
import "./utils/i18n";

document.title = `${process.env.REACT_APP_ENV_NAME} Consent Wizard`;

ReactDOM.render(
    <Provider store={store}>
        <MsalProvider instance={authProvider}>
            <AuthenticationContainer>
                <BrowserRouter>
                    <LayoutContainer>
                        <RouterContainer />
                    </LayoutContainer>
                </BrowserRouter>
            </AuthenticationContainer>
        </MsalProvider>
    </Provider>,
    document.getElementById("root")
);

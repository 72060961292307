import {
    SET_CONSENTS,
    SET_CONSENTS_ASSOCIATION_TYPE,
    IState,
    Actions,
} from "./consents-types";
import update from "immutability-helper";

const initialState: IState = {
    consents: [],
    consentAssociationType: null,
};

const consentsReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_CONSENTS:
            return update(state, {
                consents: { $set: action.consents },
            });
        case SET_CONSENTS_ASSOCIATION_TYPE:
            return update(state, {
                consentAssociationType: { $set: action.consentAssociationType },
            });
        default:
            return state;
    }
};

export default consentsReducer;

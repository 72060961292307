import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import Router from "./Router";
import { getAccountInfoIsAdministrator } from "../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    isAdministratorAccount: getAccountInfoIsAdministrator(state),
});
const RouterContainer = connect(mapStateToProps)(Router);

export default RouterContainer;

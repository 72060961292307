import i18n from "i18next";

export const obtainErrorMessage = (
    apiErrorMessage: string,
    statusCode: number
): string => {
    if (!apiErrorMessage) {
        const predefinedMessageKey = `defaultErrorMessage.statusCode${statusCode}`;
        return i18n.exists(predefinedMessageKey)
            ? i18n.t(predefinedMessageKey)
            : i18n.t("defaultErrorMessage.statusCodeUndefined");
    }

    return apiErrorMessage;
};

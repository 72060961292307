import React, { useEffect, useState } from "react";
import { routePath } from "../../utils/routes";
import {
    ConsentStatusType,
    IConsentStatus,
    IConsentState,
} from "../../redux/consents/consents-types";
import { Redirect } from "react-router-dom";
import {
    adminConsentErrorCodes,
    adminConsentErrorSubcodes,
    uriQueryParameterNames,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";

interface IComponentProps {
    updateConsentStatus: (
        customerId: string,
        consentId: string,
        consentStatus: IConsentStatus
    ) => Promise<boolean>;
    updateGuestTenantConsentStatus: (
        guestTenantId: string,
        consentId: string,
        consentStatus: IConsentStatus
    ) => Promise<boolean>;
}

const errorCodeToDescriptionKeyMapping: {
    [key: string]: { [key: string]: string };
} = {
    [adminConsentErrorCodes.accessDenied]: {
        [adminConsentErrorSubcodes.cancel]:
            "pages.consentResponse.errorDescriptions.accessDenied.cancel",
    },
};

const composeSearchQuery = (
    consentState: IConsentState | null
): string | null => {
    const queryParams: string[] = [];
    if (consentState?.customerId) {
        queryParams.push(
            `${uriQueryParameterNames.customerId}=${consentState?.customerId}`
        );
    }
    if (consentState?.guestTenantId) {
        queryParams.push(
            `${uriQueryParameterNames.guestTenantId}=${consentState?.guestTenantId}`
        );
    }
    return queryParams.length > 0 ? `?${queryParams.join("&")}` : null;
};

const ConsentResponse: React.FC<IComponentProps> = ({
    updateConsentStatus,
    updateGuestTenantConsentStatus,
}: IComponentProps) => {
    const { t } = useTranslation();
    const [isConsentStatusUpdateCompleted, setIsConsentStatusUpdateCompleted] =
        useState<boolean>(false);
    const urlParams = new URLSearchParams(location.search);
    const stringifiedConsentState = urlParams.get("state");
    const consentState = stringifiedConsentState
        ? (JSON.parse(stringifiedConsentState) as IConsentState)
        : null;
    const errorCode = urlParams.get("error") || "";
    const errorSubcode = urlParams.get("error_subcode") || "";
    const errorDescription = urlParams.get("error_description") || "";
    const getErrorDescription = (): string => {
        if (!errorDescription && errorCode && errorSubcode) {
            const errorSubcodeDescriptions =
                errorCodeToDescriptionKeyMapping[errorCode];
            const errorSubcodeDescriptionKey =
                errorSubcodeDescriptions[errorSubcode];
            return errorSubcodeDescriptionKey
                ? t(errorSubcodeDescriptionKey)
                : errorDescription;
        }
        return errorDescription;
    };
    const searchQuery = composeSearchQuery(consentState);

    useEffect(() => {
        if (consentState && !isConsentStatusUpdateCompleted) {
            const consentStatus = {
                type: errorCode
                    ? ConsentStatusType.Rejected
                    : ConsentStatusType.Granted,
                message: getErrorDescription(),
            };
            if (consentState.guestTenantId) {
                updateGuestTenantConsentStatus(
                    consentState.guestTenantId,
                    consentState.consentId,
                    consentStatus
                ).then(() => {
                    setIsConsentStatusUpdateCompleted(true);
                });
                return;
            }

            updateConsentStatus(
                consentState.customerId,
                consentState.consentId,
                consentStatus
            ).then(() => {
                setIsConsentStatusUpdateCompleted(true);
            });
        }
    }, [
        consentState,
        errorCode,
        errorDescription,
        isConsentStatusUpdateCompleted,
    ]);
    return isConsentStatusUpdateCompleted ? (
        <Redirect to={`${routePath.root}${searchQuery}`} />
    ) : null;
};

export default ConsentResponse;

import React from "react";
import { ContainerProps, Container, Header, Content, Loader } from "rsuite";
import { useTranslation } from "react-i18next";
import NavigationBarContainer from "./NavigationBar/NavigationBarContainer";
import ErrorBoundary from "./../ErrorBoundary/ErrorBoundary";
import css from "./Layout.module.css";
import cn from "classnames";
import { styleClassNames } from "../../utils/constants";
interface IComponentProps extends ContainerProps {
    isLoadingSpinnerVisible: boolean;
    errorMessage: string;
}

const Layout: React.FC<IComponentProps> = ({
    children,
    isLoadingSpinnerVisible,
    errorMessage,
}: IComponentProps) => {
    const { t } = useTranslation();
    return (
        <Container>
            <Header>
                <NavigationBarContainer />
            </Header>
            <Content className={css.contentContainer}>
                {isLoadingSpinnerVisible && (
                    <Loader
                        center
                        backdrop
                        content={t("components.layout.loadingSpinnerText")}
                        size="md"
                        speed="slow"
                        className={css.loadingSpinner}
                    />
                )}
                {errorMessage.length > 0 && (
                    <ErrorBoundary errorMessage={errorMessage} />
                )}
                <div
                    className={cn(
                        css.scrollableArea,
                        styleClassNames.pageLayoutContainer
                    )}
                >
                    {children}
                </div>
            </Content>
        </Container>
    );
};

export default Layout;

import React from "react";
import ConsentListContainer from "../../components/ConsentList/ConsentListContainer";
import AzurePortalLink from "../../components/AzurePortalLink/AzurePortalLink";
import { useTranslation } from "react-i18next";
import Paragraph from "../../components/Paragraph/Paragraph";
import parse from "html-react-parser";
import css from "./Consents.module.css";
import {
    ConsentAssociationType,
    ConsentStatusType,
    IConsent,
} from "../../redux/consents/consents-types";
import { CustomerType } from "../../redux/customer/customer-types";
import { template } from "lodash";
import { IGuestTenant } from "../../redux/account-info/account-info-types";

interface IComponentProps {
    customerType: CustomerType | null;
    accountName: string;
    consents: IConsent[];
    consentAssociationType: ConsentAssociationType | null;
    customerName: string | null;
    guestTenant: IGuestTenant | null;
}

const Consents: React.FC<IComponentProps> = ({
    customerType,
    accountName,
    consents,
    consentAssociationType,
    customerName,
    guestTenant,
}: IComponentProps) => {
    const { t } = useTranslation();
    const isEveryConsentGranted = consents.every(
        (consent) => consent.status.type === ConsentStatusType.Granted
    );
    const descriptionText = template(
        t("pages.consents.pending.descriptionTemplate")
    )({
        customer: customerName,
        environment: process.env.REACT_APP_ENV_NAME,
    });
    const getPageTranslations = () => {
        if (isEveryConsentGranted) {
            return [
                t("pages.consents.success.title"),
                t("pages.consents.success.descriptionTemplate"),
            ];
        } else if (
            customerType === CustomerType.DynamicsAX ||
            consentAssociationType === ConsentAssociationType.GuestTenant
        ) {
            return [
                template(
                    t("pages.consents.pending.dynamicsArea.titleTemplate")
                )({ userName: accountName }),
                template(
                    t("pages.consents.pending.dynamicsArea.descriptionTemplate")
                )({
                    customer: guestTenant?.customerName,
                    tenantName: guestTenant?.tenantName,
                    environment: process.env.REACT_APP_ENV_NAME,
                }),
            ];
        }
        return [t("pages.consents.pending.title"), descriptionText];
    };
    const [title, description] = getPageTranslations();
    return (
        <Paragraph title={title} childContainerColspan={24}>
            <div className={css.paragraphDescription}>{parse(description)}</div>
            <ConsentListContainer />
            <div className={css.azurePortalLinkContainer}>
                <AzurePortalLink />
            </div>
        </Paragraph>
    );
};

export default Consents;

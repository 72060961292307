export const SET_CUSTOMER_ID = "customerReducer/SET_CUSTOMER_ID";
export const SET_CUSTOMER_TYPE = "customerReducer/SET_CUSTOMER_TYPE";

export enum CustomerType {
    D365 = 0,
    DynamicsAX = 1,
    Empty = "",
}
export interface IState {
    id: string;
    type: CustomerType | null;
}

export interface ISetCustomerId {
    type: typeof SET_CUSTOMER_ID;
    customerId: string;
}

export interface ISetCustomerType {
    type: typeof SET_CUSTOMER_TYPE;
    customerType: CustomerType | null;
}

export type Actions = ISetCustomerId | ISetCustomerType;

import {
    SET_CUSTOMER_ID,
    SET_CUSTOMER_TYPE,
    IState,
    Actions,
} from "./customer-types";
import update from "immutability-helper";

const initialState: IState = {
    id: "",
    type: null,
};

const customerReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_CUSTOMER_ID:
            return update(state, {
                id: { $set: action.customerId },
            });
        case SET_CUSTOMER_TYPE:
            return update(state, {
                type: { $set: action.customerType },
            });
        default:
            return state;
    }
};

export default customerReducer;

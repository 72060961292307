import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import NavigationBar from "./NavigationBar";
import {
    getAccountInfoName,
    getAccountInfoUserName,
} from "../../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    accountName: getAccountInfoName(state),
    accountUserName: getAccountInfoUserName(state),
});
const NavigationBarContainer = connect(mapStateToProps)(NavigationBar);

export default NavigationBarContainer;

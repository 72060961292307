import React from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "../../../components/Paragraph/Paragraph";
import parse from "html-react-parser";
import stepImage from "../../../assets/images/instructionSteps/2.jpg";
import InstructionImage from "../../../components/InstructionImage/InstructionImage";

const SecondPage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Paragraph title={t("pages.instructions.secondPage.paragraphTitle")}>
            {parse(t("pages.instructions.secondPage.paragraphDescriptionText"))}
            <div style={{ paddingBottom: "32px" }}>
                <InstructionImage imageSource={stepImage} />
            </div>
        </Paragraph>
    );
};

export default SecondPage;

import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import FirstPage from "./FirstPage";
import { getAccountInfoName } from "../../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    accountName: getAccountInfoName(state),
});
const FirstPageContainer = connect(mapStateToProps)(FirstPage);

export default FirstPageContainer;

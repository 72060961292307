import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import Layout from "./Layout";
import { getIsLoadingSpinnerVisible } from "../../redux/loading-spinner/loading-spinner-selectors";
import { getErrorMessage } from "../../redux/errors/errors-selectors";

const mapStateToProps = (state: RootState) => ({
    isLoadingSpinnerVisible: getIsLoadingSpinnerVisible(state),
    errorMessage: getErrorMessage(state),
});
const LayoutContainer = connect(mapStateToProps)(Layout);

export default LayoutContainer;

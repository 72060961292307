import { RootState } from "../store";
import {
    ConsentAreaType,
    ConsentAssociationType,
    IConsent,
} from "./consents-types";
import { createSelector } from "reselect";
import { orderBy, template } from "lodash";
import i18n from "i18next";

export const getConsents = (state: RootState): IConsent[] =>
    state.consentsReducer.consents;

export const getConsentAssociationType = (
    state: RootState
): ConsentAssociationType | null =>
    state.consentsReducer.consentAssociationType;

export const getDescriptionTemplateByArea = (area: ConsentAreaType) => {
    switch (area) {
        case ConsentAreaType.Dooap:
            return i18n.t(
                "components.consentList.listItem.dooapAreaDescriptionTemplate"
            );
        case ConsentAreaType.D365:
            return i18n.t(
                "components.consentList.listItem.dynamicsAreaDescriptionTemplate"
            );
        default:
            return i18n.t(
                "components.consentList.listItem.defaultAreaDescriptionTemplate"
            );
    }
};
export const getSortedConsentsSelector = createSelector(
    [getConsents],
    (consents) => {
        consents.forEach((consent) => {
            consent.templatedName = template(
                getDescriptionTemplateByArea(consent.area)
            )({
                applicationName: consent.displayName,
                environmentName: consent.environmentName,
            });
        });
        return orderBy(
            consents,
            [
                (consent) => consent.order,
                (consent) => consent.templatedName,
                (consent) => consent.environmentName,
            ],
            ["asc", "asc", "asc"]
        );
    }
);

import { AppThunk } from "../store";
import {
    getDirectoryIdByCustomerId,
    getCustomerTypeByCustomerId,
} from "../../api/consent-management";
import { successfulResponseStatusCodes } from "../../utils/constants";
import { obtainErrorMessage } from "../../utils/error-message-provider";
import { setCustomerType } from "./customer-actions";
import { toggleLoadingSpinner } from "../loading-spinner/loading-spinner-actions";
import { setErrorMessage } from "../errors/errors-actions";

export const getTypeByCustomerId =
    (customerId: string): AppThunk =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data: customerType, statusCode } =
            await getCustomerTypeByCustomerId(customerId);
        if (successfulResponseStatusCodes.includes(statusCode)) {
            dispatch(setCustomerType(customerType));
        } else {
            dispatch(
                setErrorMessage(
                    obtainErrorMessage(JSON.stringify(customerType), statusCode)
                )
            );
        }
        dispatch(toggleLoadingSpinner(false));
    };

export const isCustomerBoundToAnyDirectory =
    (customerId: string): AppThunk<Promise<boolean>> =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data: directoryId, statusCode } =
            await getDirectoryIdByCustomerId(customerId);
        dispatch(toggleLoadingSpinner(false));
        if (successfulResponseStatusCodes.includes(statusCode)) {
            return !!directoryId;
        } else {
            dispatch(
                setErrorMessage(
                    obtainErrorMessage(JSON.stringify(directoryId), statusCode)
                )
            );
        }
        return false;
    };

import React from "react";
import { Navbar, Nav, Button, FlexboxGrid, Whisper, Popover } from "rsuite";
import Logo from "../../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import css from "./NavigationBar.module.css";
import AccountIcon from "../../../assets/images/account.svg";
import AppVersion from "../AppVersion/AppVersion";
import { logoutCurrentUser } from "../../../utils/auth-provider";
interface IComponentProps {
    accountName: string;
    accountUserName: string;
}

const NavigationBar: React.FC<IComponentProps> = ({
    accountName,
    accountUserName,
}: IComponentProps) => {
    const { t } = useTranslation();

    const logoutPopover = (
        <Popover title={accountName} className={css.logoutPopover}>
            <p className={css.logoutPopoverContent}>{accountUserName}</p>
            <div className={css.logoutPopoverFooter}>
                <Button
                    onClick={() => {
                        logoutCurrentUser();
                    }}
                    appearance="primary"
                >
                    {t("components.layout.navigationBar.logoutButtonText")}
                </Button>
            </div>
        </Popover>
    );

    return (
        <Navbar className={css.navigationBar}>
            <Navbar.Header className={css.navigationBarHeader}>
                <FlexboxGrid align={"middle"} className={css.logoContainer}>
                    <FlexboxGrid.Item>
                        <img src={Logo} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Navbar.Header>
            <Navbar.Body>
                <Nav pullRight className={css.rightSection}>
                    <AppVersion />
                    <Whisper
                        placement="bottomEnd"
                        trigger="click"
                        speaker={logoutPopover}
                        preventOverflow
                    >
                        <div className={css.usernameBtn}>
                            <span>{accountName}</span>
                            <img src={AccountIcon} />
                        </div>
                    </Whisper>
                </Nav>
            </Navbar.Body>
        </Navbar>
    );
};

export default NavigationBar;

import React, { useEffect, useState } from "react";
import { FlexboxGrid } from "rsuite";
import DogSteps from "../../components/DogSteps/DogSteps";
import ParagraphButton from "../../components/ParagraphButton/ParagraphButton";
import {
    ConsentAssociationType,
    ConsentStatusType,
    IConsent,
} from "../../redux/consents/consents-types";
import { CustomerType } from "../../redux/customer/customer-types";
import {
    IInstruction,
    IWizardStep,
    WizardStepStatusType,
    WizardStepType,
} from "../../utils/types/wizard-types";
import { getInstructions } from "../../utils/instructions";
import update from "immutability-helper";
import css from "./Wizard.module.css";
import { useTranslation } from "react-i18next";
import ConsentsContainer from "../Consents/ConsentsContainer";
import { logoutCurrentUser } from "../../utils/auth-provider";
import {
    styleClassNames,
    successfulResponseStatusCodes,
} from "../../utils/constants";
import cn from "classnames";
import { IGuestTenant } from "../../redux/account-info/account-info-types";

interface IComponentProps {
    accountTenantId: string;
    accountName: string;
    customerName: string | null;
    guestTenant: IGuestTenant | null;
    consentCustomerId: string;
    directoryGuestTenantId: string;
    consentGuestTenantId: string;
    isDirectoryCustomerIdLoaded: boolean;
    isDirectoryGuestTenantIdLoaded: boolean;
    customerType: CustomerType | null;
    hasCustomerDirectoryId: boolean;
    hasGuestTenantDirectoryId: boolean;
    consents: IConsent[];
    consentAssociationType: ConsentAssociationType | null;
    getConsentsByCustomer: (customerId: string) => void;
    getConsentsByGuestTenant: (guestTenantId: string) => void;
    updateCustomerDirectory: (
        customerId: string,
        directoryId: string,
        accountName: string,
        customerName: string | null
    ) => Promise<void>;
    updateGuestTenantDirectory: (
        guestTenantId: string,
        directoryId: string,
        accountName: string,
        customerName: string,
        tenantName: string
    ) => Promise<number>;
    getCustomerByDirectoryId: (id: string) => void;
    getGuestTenantByDirectoryId: (
        directoryId: string,
        customerId: string,
        guestTenantId: string
    ) => void;
}

const Wizard: React.FC<IComponentProps> = ({
    accountTenantId,
    accountName,
    customerName,
    guestTenant,
    consentCustomerId,
    directoryGuestTenantId,
    consentGuestTenantId,
    isDirectoryCustomerIdLoaded,
    isDirectoryGuestTenantIdLoaded,
    customerType,
    hasCustomerDirectoryId,
    hasGuestTenantDirectoryId,
    consents,
    consentAssociationType,
    getConsentsByCustomer,
    getConsentsByGuestTenant,
    updateCustomerDirectory,
    updateGuestTenantDirectory,
    getCustomerByDirectoryId,
    getGuestTenantByDirectoryId,
}: IComponentProps) => {
    const { t } = useTranslation();
    const [wizardSteps, setWizardSteps] = useState<IWizardStep[]>([]);
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const [activeStep, setActiveStep] = useState<IWizardStep | null>(null);
    const [isConsentsLoadingStarted, setIsConsentsLoadingStarted] =
        useState<boolean>(false);

    const mapInstructionToWizardStep = (
        instruction: IInstruction,
        isResolved: boolean
    ) => ({
        type: WizardStepType.Instruction,
        status: isResolved
            ? WizardStepStatusType.Resolved
            : WizardStepStatusType.Pending,
        description: instruction.description,
        page: instruction.page,
    });
    const approveInstructionStep = () => {
        setActiveStepIndex(activeStepIndex + 1);
        setWizardSteps(
            update(wizardSteps, {
                [activeStepIndex]: {
                    status: {
                        $set: WizardStepStatusType.Resolved,
                    },
                },
            })
        );
    };
    const getConsentsWizardStep = (consents: IConsent[]) => {
        const consentsWizardStep: IWizardStep = {
            type: WizardStepType.Consent,
            status: WizardStepStatusType.Pending,
            description: t("pages.wizard.consentsWizardStep.description"),
            page: <ConsentsContainer />,
        };
        const concatStepDescription = (textToConcat: string) => {
            return [consentsWizardStep.description, textToConcat].join(". ");
        };
        if (
            consents.some(
                (consent) => consent.status.type === ConsentStatusType.Rejected
            )
        ) {
            consentsWizardStep.description = concatStepDescription(
                t("pages.wizard.consentsWizardStep.failedConsentMessage")
            );
            consentsWizardStep.status = WizardStepStatusType.Failed;
        } else if (
            consents.every(
                (consent) => consent.status.type === ConsentStatusType.Granted
            )
        ) {
            consentsWizardStep.description = concatStepDescription(
                t("pages.wizard.consentsWizardStep.allConsentAcceptedMessage")
            );
            consentsWizardStep.status = WizardStepStatusType.Resolved;
        }
        return consentsWizardStep;
    };

    useEffect(() => {
        if (
            activeStep?.type === WizardStepType.Consent &&
            consentAssociationType === ConsentAssociationType.Customer &&
            consentCustomerId &&
            accountTenantId &&
            isDirectoryCustomerIdLoaded &&
            !hasCustomerDirectoryId
        ) {
            updateCustomerDirectory(
                consentCustomerId,
                accountTenantId,
                accountName,
                customerName
            ).then(() => {
                getCustomerByDirectoryId(accountTenantId);
            });
        }
    }, [
        activeStep,
        consentCustomerId,
        accountTenantId,
        isDirectoryCustomerIdLoaded,
        hasCustomerDirectoryId,
        consentAssociationType,
    ]);
    useEffect(() => {
        if (
            activeStep?.type === WizardStepType.Consent &&
            consentAssociationType === ConsentAssociationType.GuestTenant &&
            consentGuestTenantId &&
            accountTenantId &&
            guestTenant &&
            isDirectoryGuestTenantIdLoaded &&
            !hasGuestTenantDirectoryId
        ) {
            updateGuestTenantDirectory(
                consentGuestTenantId,
                accountTenantId,
                accountName,
                guestTenant?.customerName ?? "",
                guestTenant?.tenantName
            ).then((statusCode) => {
                if (successfulResponseStatusCodes.includes(statusCode)) {
                    getGuestTenantByDirectoryId(
                        accountTenantId,
                        consentCustomerId,
                        consentGuestTenantId
                    );
                }
            });
        }
    }, [
        activeStep,
        consentGuestTenantId,
        accountTenantId,
        isDirectoryGuestTenantIdLoaded,
        hasGuestTenantDirectoryId,
        consentAssociationType,
    ]);

    useEffect(() => {
        if (
            consentAssociationType === ConsentAssociationType.Customer &&
            !isConsentsLoadingStarted &&
            consentCustomerId &&
            isDirectoryCustomerIdLoaded
        ) {
            setIsConsentsLoadingStarted(true);
            getConsentsByCustomer(consentCustomerId);
        }
    }, [
        consentCustomerId,
        isDirectoryCustomerIdLoaded,
        hasCustomerDirectoryId,
        consentAssociationType,
    ]);

    useEffect(() => {
        if (
            consentAssociationType === ConsentAssociationType.GuestTenant &&
            !isConsentsLoadingStarted &&
            isDirectoryGuestTenantIdLoaded &&
            (directoryGuestTenantId
                ? directoryGuestTenantId === consentGuestTenantId
                : consentGuestTenantId)
        ) {
            setIsConsentsLoadingStarted(true);
            getConsentsByGuestTenant(consentGuestTenantId);
        }
    }, [
        consentGuestTenantId,
        isDirectoryGuestTenantIdLoaded,
        hasGuestTenantDirectoryId,
        consentAssociationType,
    ]);

    useEffect(() => {
        if (consents.length > 0) {
            const isProcessedConsentExist = consents.some(
                (consent) =>
                    consent.status.type !== ConsentStatusType.NotGranted
            );
            const instructionsWizardSteps =
                customerType !== CustomerType.DynamicsAX &&
                consentAssociationType !== ConsentAssociationType.GuestTenant
                    ? getInstructions().map((instruction) =>
                          mapInstructionToWizardStep(
                              instruction,
                              isProcessedConsentExist
                          )
                      )
                    : [];
            const wizardSteps = instructionsWizardSteps.concat(
                getConsentsWizardStep(consents)
            );
            setWizardSteps(wizardSteps);
            const consentWizardStepIndex = wizardSteps.findIndex(
                (step) => step.type === WizardStepType.Consent
            );
            setActiveStepIndex(
                isProcessedConsentExist && consentWizardStepIndex > 0
                    ? consentWizardStepIndex
                    : 0
            );
        }
    }, [customerType, consents, consentAssociationType]);

    useEffect(() => {
        setActiveStep(wizardSteps[activeStepIndex] || null);
    }, [wizardSteps, activeStepIndex]);

    const isEveryConsentGranted = consents.every(
        (consent) => consent.status.type === ConsentStatusType.Granted
    );

    const isPageScrollable = activeStep?.type === WizardStepType.Consent;

    return (
        <FlexboxGrid justify="center" className={css.wizardPageContainer}>
            <FlexboxGrid.Item
                colspan={21}
                className={cn({
                    [styleClassNames.scrollableContainer]: isPageScrollable,
                })}
            >
                <div className={css.dogStepsContainer}>
                    <DogSteps
                        wizardSteps={wizardSteps}
                        activeStepIndex={activeStepIndex}
                        setActiveStepIndex={setActiveStepIndex}
                    />
                </div>
                {activeStep !== null ? (
                    <FlexboxGrid
                        justify="start"
                        className={styleClassNames.overflowContainer}
                    >
                        <FlexboxGrid.Item
                            colspan={24}
                            className={styleClassNames.scrollableContainer}
                        >
                            {activeStep.page}
                            {activeStep.type === WizardStepType.Instruction ? (
                                <ParagraphButton
                                    buttonText={t(
                                        "pages.wizard.nextInstructionButtonText"
                                    )}
                                    textAbove={t(
                                        "pages.wizard.nextInstructionButtonAboveText"
                                    )}
                                    onClick={approveInstructionStep}
                                />
                            ) : (
                                <ParagraphButton
                                    buttonText={t(
                                        isEveryConsentGranted
                                            ? "pages.wizard.consentsDoneButtonText"
                                            : "pages.wizard.continueLaterButtonText"
                                    )}
                                    textAbove={""}
                                    className={css.nextStepButtonContainer}
                                    onClick={logoutCurrentUser}
                                />
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                ) : null}
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};

export default Wizard;

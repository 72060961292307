import React from "react";
import css from "./InstructionImage.module.css";
import { useTranslation } from "react-i18next";
import { FlexboxGrid } from "rsuite";

interface IComponentProps {
    imageSource: string;
    imageColspan?: number;
}

const InstructionImage: React.FC<IComponentProps> = ({
    imageSource,
    imageColspan = 18,
}: IComponentProps) => {
    const { t } = useTranslation();
    return (
        <FlexboxGrid justify="start" style={{ margin: "10px 0 10px 0" }}>
            <FlexboxGrid.Item colspan={imageColspan}>
                <div className={css.instructionImageContainer}>
                    <div className={css.descriptionContainer}>
                        {t("components.instructionImage.descriptionText")}
                    </div>
                    <img src={imageSource} className={css.image} />
                </div>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};

export default InstructionImage;

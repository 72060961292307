export enum WizardStepType {
    Instruction,
    Consent,
}

export enum WizardStepStatusType {
    Pending,
    Resolved,
    Failed,
}

export interface IWizardStep {
    type: WizardStepType;
    status: WizardStepStatusType;
    description: string;
    page: JSX.Element;
}

export interface IInstruction {
    description: string;
    page: JSX.Element;
}

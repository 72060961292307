import { SET_ERROR_MESSAGE, IState, Actions } from "./errors-types";
import update from "immutability-helper";

const initialState: IState = {
    errorMessage: "",
};

const errorsReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_ERROR_MESSAGE:
            return update(state, {
                errorMessage: { $set: action.errorMessage },
            });
        default:
            return state;
    }
};

export default errorsReducer;

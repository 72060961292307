import parse from "html-react-parser";
import React from "react";
import { Button, ContainerProps, FlexboxGrid } from "rsuite";
import css from "./ParagraphButton.module.css";

interface IComponentProps extends ContainerProps {
    buttonText: string;
    textAbove: string;
    onClick: () => void;
}

const ParagraphButton: React.FC<IComponentProps> = ({
    buttonText,
    textAbove,
    className,
    onClick,
}: IComponentProps) => {
    const DescriptionText = ({ text }: { text: string }) => (
        <div className={css.descriptionTextContainer}>{parse(text)}</div>
    );
    return (
        <FlexboxGrid justify="start" className={className}>
            <FlexboxGrid.Item colspan={24}>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={22}>
                        <div style={{ marginBottom: "10px" }}>
                            <DescriptionText text={textAbove} />
                        </div>
                        <Button
                            appearance={"primary"}
                            onClick={onClick}
                            className={css.paragraphButton}
                        >
                            {buttonText}
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};

export default ParagraphButton;

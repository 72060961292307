import {
    PublicClientApplication,
    BrowserCacheLocation,
    RedirectRequest,
} from "@azure/msal-browser";

export const loginRedirectRequest: RedirectRequest = {
    scopes: [
        "openid",
        "profile",
        `${String(process.env.REACT_APP_CLIENT_ID)}/.default`,
    ],
};

export const authProvider = new PublicClientApplication({
    auth: {
        clientId: String(process.env.REACT_APP_CLIENT_ID),
        authority: String(process.env.REACT_APP_AUTHORITY),
        redirectUri: String(process.env.REACT_APP_BASE_URI),
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false,
    },
});

export function logoutCurrentUser(): void {
    authProvider.logout({
        postLogoutRedirectUri: `${location.origin}/${location.search}`,
    });
}

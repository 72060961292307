import {
    TOGGLE_LOADING_SPINNER,
    IState,
    Actions,
} from "./loading-spinner-types";
import update from "immutability-helper";

const initialState: IState = {
    count: 0,
};

const loadingSpinnerReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case TOGGLE_LOADING_SPINNER:
            return update(state, {
                count: {
                    $set: action.isVisible ? state.count + 1 : state.count - 1,
                },
            });
        default:
            return state;
    }
};

export default loadingSpinnerReducer;

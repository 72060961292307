import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import { setCustomerId } from "../../redux/customer/customer-actions";
import { getCustomerId } from "../../redux/customer/customer-selectors";
import { setGuestTenantId } from "../../redux/guest-tenants/guest-tenant-actions";
import ControlAppRedirect from "./ControlAppRedirect";
import { setConsentAssociationType } from "../../redux/consents/consents-actions";
import { getGuestTenantId } from "../../redux/guest-tenants/guest-tenant-selectors";
import { getDirectoryCustomerId } from "../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    directoryCustomerId: getDirectoryCustomerId(state),
    consentCustomerId: getCustomerId(state),
    consentGuestTenantId: getGuestTenantId(state),
});
const ControlAppRedirectContainer = connect(mapStateToProps, {
    setConsentCustomerId: setCustomerId,
    setGuestTenantId,
    setConsentAssociationType,
})(ControlAppRedirect);

export default ControlAppRedirectContainer;

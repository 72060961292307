import axios from "axios";
import { authProvider, loginRedirectRequest } from "./auth-provider";
import { responseStatusCodes } from "../utils/constants";

export type ResponseResult<T> = { data: T; statusCode: number };

export const getBadRequestResponseResult = <T>(
    defaultValue: T
): ResponseResult<T> => ({
    data: defaultValue,
    statusCode: responseStatusCodes.BadRequest,
});

export const API = axios.create({
    baseURL: `${String(process.env.REACT_APP_BASE_URI)}/api/`,
});

API.interceptors.request.use(
    async (config) => {
        const accounts = authProvider.getAllAccounts();
        const account = accounts[0] || null;
        try {
            const { accessToken } = await authProvider.acquireTokenSilent({
                account: account,
                scopes: loginRedirectRequest.scopes,
            });
            config.headers = {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
            };
        } catch (error) {
            await authProvider.acquireTokenRedirect(loginRedirectRequest);
        }

        return config;
    },
    async (error) => await error
);

API.interceptors.response.use(
    async (response) => response,
    async (error) => await error.response
);

import {
    TOGGLE_LOADING_SPINNER,
    IToggleLoadingSpinner,
} from "./loading-spinner-types";

export const toggleLoadingSpinner = (
    isVisible: boolean
): IToggleLoadingSpinner => ({
    type: TOGGLE_LOADING_SPINNER,
    isVisible,
});

// Denotes the tenant-wide roles assigned to this user, from the section of roles present in the admin roles page.
// Admin roles page: https://docs.microsoft.com/en-us/azure/active-directory/roles/permissions-reference#role-template-ids
export const azureADRoleTemplateIds = {
    globalAdministrator: "62e90394-69f5-4237-9190-012177145e10",
    applicationAdministrator: "9b895d92-2cd3-44c7-9d02-a6ac2d5ea5c3",
    cloudApplicationAdministrator: "158c047a-c907-4556-b7ef-446551a6b5f7",
};

export const responseStatusCodes = {
    OK: 200,
    NoContent: 204,
    BadRequest: 400,
    Unauthorized: 401,
    NotFound: 404,
    InternalServerError: 500,
};

export const successfulResponseStatusCodes = [
    responseStatusCodes.OK,
    responseStatusCodes.NoContent,
];

export const appVersionDefault = "v.1.0.0.0";

export const adminConsentErrorCodes = {
    accessDenied: "access_denied",
};

export const adminConsentErrorSubcodes = {
    cancel: "cancel",
};

export const styleClassNames = {
    pageLayoutContainer: "page-layout-container",
    scrollableContainer: "scrollable-container",
    overflowContainer: "overflow-container",
};

export const uriQueryParameterNames = {
    customerId: "customerId",
    guestTenantId: "guestTenantId",
};

import React from "react";
import { ContainerProps } from "rsuite";
import cn from "classnames";
import css from "./BaseImage.module.css";

interface IComponentProps extends ContainerProps {
    imageSource: string;
    hasBackground?: boolean;
    hasPadding?: boolean;
}

const BaseImage: React.FC<IComponentProps> = ({
    imageSource,
    hasBackground,
    hasPadding,
    style,
    onClick,
}: IComponentProps) => (
    <div
        className={cn(css.baseImage, {
            [css.background]: hasBackground,
            [css.padding]: hasPadding,
        })}
        style={style}
        onClick={onClick}
    >
        <img src={imageSource} />
    </div>
);

export default BaseImage;

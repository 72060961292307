import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import Wizard from "./Wizard";
import {
    getConsentAssociationType,
    getSortedConsentsSelector,
} from "../../redux/consents/consents-selectors";
import {
    getConsentsByCustomer,
    getConsentsByGuestTenant,
} from "../../redux/consents/consents-thunk";
import {
    getCustomerId,
    getCustomerType,
} from "../../redux/customer/customer-selectors";
import {
    getCustomerByDirectoryId,
    getGuestTenantByDirectoryId,
    updateCustomerDirectory,
    updateGuestTenantDirectory,
} from "../../redux/account-info/account-info-thunk";
import { getGuestTenantId } from "../../redux/guest-tenants/guest-tenant-selectors";
import {
    getAccountInfoName,
    getAccountInfoTenantId,
    getIsDirectoryCustomerIdLoaded,
    getHasCustomerDirectoryId,
    getIsDirectoryGuestTenantIdLoaded,
    getHasGuestTenantDirectoryId,
    getDirectoryGuestTenantId,
    getCustomerName,
    getGuestTenant,
} from "../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    accountTenantId: getAccountInfoTenantId(state),
    accountName: getAccountInfoName(state),
    customerName: getCustomerName(state),
    guestTenant: getGuestTenant(state),
    consentCustomerId: getCustomerId(state),
    directoryGuestTenantId: getDirectoryGuestTenantId(state),
    consentGuestTenantId: getGuestTenantId(state),
    isDirectoryCustomerIdLoaded: getIsDirectoryCustomerIdLoaded(state),
    hasCustomerDirectoryId: getHasCustomerDirectoryId(state),
    customerType: getCustomerType(state),
    isDirectoryGuestTenantIdLoaded: getIsDirectoryGuestTenantIdLoaded(state),
    hasGuestTenantDirectoryId: getHasGuestTenantDirectoryId(state),
    consents: getSortedConsentsSelector(state),
    consentAssociationType: getConsentAssociationType(state),
});
const WizardContainer = connect(mapStateToProps, {
    getConsentsByCustomer,
    getConsentsByGuestTenant,
    updateCustomerDirectory,
    updateGuestTenantDirectory,
    getCustomerByDirectoryId,
    getGuestTenantByDirectoryId,
})(Wizard);

export default WizardContainer;

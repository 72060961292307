import React from "react";
import {
    IConsent,
    ConsentStatusType,
} from "../../redux/consents/consents-types";
import ListItem from "./ListItem/ListItem";
import styles from "./ConsentList.module.css";
import { styleClassNames } from "../../utils/constants";
interface IComponentProps {
    consents: IConsent[];
}

const ConsentList: React.FC<IComponentProps> = ({
    consents,
}: IComponentProps) => (
    <div className={styleClassNames.overflowContainer}>
        {consents.map((consent, index) => {
            const isFirstConsent = index === 0;
            const isEveryPreviousConsentGranted =
                index > 0 &&
                consents
                    .filter((consent, consentIndex) => consentIndex < index)
                    .every(
                        (consent) =>
                            consent.status.type === ConsentStatusType.Granted
                    );
            return (
                <div key={String(index)} className={styles.listItemContainer}>
                    <ListItem
                        consent={consent}
                        isLinkEnabled={
                            consent.status.type !== ConsentStatusType.Granted &&
                            (isFirstConsent || isEveryPreviousConsentGranted)
                        }
                    />
                </div>
            );
        })}
    </div>
);

export default ConsentList;

import React from "react";
import { ConsentStatusType } from "../../../redux/consents/consents-types";
import BoneLarge from "../../../assets/images/bone-large.svg";
import BoneLargeGreen from "../../../assets/images/bone-large-green.svg";
import BoneLargeRed from "../../../assets/images/bone-large-red.svg";

interface IComponentProps {
    status: ConsentStatusType;
}

const consentStatusToBoneImageMapping = {
    [ConsentStatusType.Granted]: BoneLargeGreen,
    [ConsentStatusType.NotGranted]: BoneLarge,
    [ConsentStatusType.Rejected]: BoneLargeRed,
};

const ListItemImage: React.FC<IComponentProps> = ({
    status,
}: IComponentProps) => <img src={consentStatusToBoneImageMapping[status]} />;

export default ListItemImage;

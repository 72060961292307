import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import Consents from "./Consents";
import { getCustomerType } from "../../redux/customer/customer-selectors";
import {
    getAccountInfoName,
    getCustomerName,
    getGuestTenant,
} from "../../redux/account-info/account-info-selectors";
import {
    getConsentAssociationType,
    getConsents,
} from "../../redux/consents/consents-selectors";

const mapStateToProps = (state: RootState) => ({
    customerType: getCustomerType(state),
    accountName: getAccountInfoName(state),
    consents: getConsents(state),
    consentAssociationType: getConsentAssociationType(state),
    customerName: getCustomerName(state),
    guestTenant: getGuestTenant(state),
});

const ConsentsContainer = connect(mapStateToProps)(Consents);

export default ConsentsContainer;

import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import AdminConsentLink from "./AdminConsentLink";
import { getCustomerId } from "../../../redux/customer/customer-selectors";
import { getAccountInfoTenantId } from "../../../redux/account-info/account-info-selectors";
import { getGuestTenantId } from "../../../redux/guest-tenants/guest-tenant-selectors";

const mapStateToProps = (state: RootState) => ({
    tenantId: getAccountInfoTenantId(state),
    consentCustomerId: getCustomerId(state),
    guestTenantId: getGuestTenantId(state),
});
const AdminConsentLinkContainer = connect(mapStateToProps)(AdminConsentLink);

export default AdminConsentLinkContainer;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ContainerProps } from "rsuite";
import { ConsentAssociationType } from "../../../redux/consents/consents-types";
import { uriQueryParameterNames } from "../../../utils/constants";

interface IComponentProps extends ContainerProps {
    directoryCustomerId: string;
    consentCustomerId: string;
    directoryGuestTenantId: string;
    consentGuestTenantId: string;
    isDirectoryGuestTenantIdLoaded: boolean;
    setConsentCustomerId: (customerId: string) => void;
    setGuestTenantId: (customerId: string) => void;
    setConsentAssociationType: (
        consentAssociationType: ConsentAssociationType
    ) => void;
}

const CustomerQueryRoute: React.FC<IComponentProps> = ({
    children,
    directoryCustomerId,
    consentCustomerId,
    directoryGuestTenantId,
    consentGuestTenantId,
    isDirectoryGuestTenantIdLoaded,
    setConsentCustomerId,
    setGuestTenantId,
    setConsentAssociationType,
}: IComponentProps) => {
    const history = useHistory();
    const [isCustomerIdConfigured, setIsCustomerIdConfigured] =
        useState<boolean>(false);
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const customerIdParameterValue = urlParams.get(
            uriQueryParameterNames.customerId
        );
        const guestTenantIdParameterValue = urlParams.get(
            uriQueryParameterNames.guestTenantId
        );

        let consentAssociationType: ConsentAssociationType | null =
            directoryCustomerId || customerIdParameterValue
                ? ConsentAssociationType.Customer
                : null;
        consentAssociationType =
            directoryGuestTenantId ||
            consentGuestTenantId ||
            guestTenantIdParameterValue
                ? ConsentAssociationType.GuestTenant
                : consentAssociationType;

        const newConsentCustomerId: string | null =
            directoryCustomerId || customerIdParameterValue;
        if (
            consentAssociationType === ConsentAssociationType.Customer &&
            !consentCustomerId &&
            newConsentCustomerId
        ) {
            setConsentCustomerId(newConsentCustomerId);
        } else if (
            consentAssociationType === ConsentAssociationType.GuestTenant &&
            !consentCustomerId &&
            customerIdParameterValue
        ) {
            setConsentCustomerId(customerIdParameterValue);
        }

        const newConsentGuestTenantId: string | null =
            isDirectoryGuestTenantIdLoaded && directoryGuestTenantId
                ? directoryGuestTenantId
                : consentGuestTenantId || guestTenantIdParameterValue;

        if (consentCustomerId) {
            urlParams.set(uriQueryParameterNames.customerId, consentCustomerId);

            if (newConsentGuestTenantId) {
                urlParams.set(
                    uriQueryParameterNames.guestTenantId,
                    newConsentGuestTenantId
                );
            }

            history.push({ search: urlParams.toString() });
        }

        if (
            consentGuestTenantId !== newConsentGuestTenantId &&
            newConsentGuestTenantId
        ) {
            setGuestTenantId(newConsentGuestTenantId);
        }

        if (consentAssociationType) {
            setConsentAssociationType(consentAssociationType);
        }
        setIsCustomerIdConfigured(true);
    }, [
        directoryCustomerId,
        consentCustomerId,
        directoryGuestTenantId,
        consentGuestTenantId,
        isDirectoryGuestTenantIdLoaded,
    ]);
    return isCustomerIdConfigured ? <>{children}</> : null;
};

export default CustomerQueryRoute;

import { connect } from "react-redux";
import { updateCustomerConsent } from "../../redux/consents/consents-thunk";
import { updateGuestTenantConsent } from "../../redux/consents/consents-thunk";
import ConsentResponse from "./ConsentResponse";

const ConsentResponseContainer = connect(null, {
    updateConsentStatus: updateCustomerConsent,
    updateGuestTenantConsentStatus: updateGuestTenantConsent,
})(ConsentResponse);

export default ConsentResponseContainer;

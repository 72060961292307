import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import Authentication from "./Authentication";
import { setAccountInfo } from "../../redux/account-info/account-info-actions";
import { setErrorMessage } from "../../redux/errors/errors-actions";
import {
    getTypeByCustomerId,
    isCustomerBoundToAnyDirectory,
} from "../../redux/customer/customer-thunk";
import {
    getAccountInfo,
    getDirectoryCustomerId,
    getHasCustomerDirectoryId,
    getIsDirectoryCustomerIdLoaded,
    getIsDirectoryGuestTenantIdLoaded,
} from "../../redux/account-info/account-info-selectors";
import { getGuestTenantId } from "../../redux/guest-tenants/guest-tenant-selectors";
import {
    getCustomerByDirectoryId,
    getGuestTenantByDirectoryId,
} from "../../redux/account-info/account-info-thunk";
import {
    getCustomerId,
    getCustomerType,
} from "../../redux/customer/customer-selectors";
import { getConsentAssociationType } from "../../redux/consents/consents-selectors";

const mapStateToProps = (state: RootState) => ({
    accountInfo: getAccountInfo(state),
    directoryCustomerId: getDirectoryCustomerId(state),
    guestTenantId: getGuestTenantId(state),
    isDirectoryCustomerIdLoaded: getIsDirectoryCustomerIdLoaded(state),
    hasCustomerDirectoryId: getHasCustomerDirectoryId(state),
    consentCustomerId: getCustomerId(state),
    customerType: getCustomerType(state),
    isDirectoryGuestTenantIdLoaded: getIsDirectoryGuestTenantIdLoaded(state),
    consentAssociationType: getConsentAssociationType(state),
});
const AuthenticationContainer = connect(mapStateToProps, {
    setAccountInfo,
    getTypeByCustomerId,
    getCustomerByDirectoryId,
    getGuestTenantByDirectoryId,
    setErrorMessage,
    isCustomerBoundToAnyDirectory,
})(Authentication);

export default AuthenticationContainer;

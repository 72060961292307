import React from "react";
import { ContainerProps, FlexboxGrid } from "rsuite";
import css from "./Paragraph.module.css";
import cn from "classnames";
import { styleClassNames } from "../../utils/constants";

interface IComponentProps extends ContainerProps {
    title: string;
    childContainerColspan?: number;
}

const Paragraph: React.FC<IComponentProps> = ({
    title,
    childContainerColspan = 15,
    children,
    style,
}: IComponentProps) => (
    <FlexboxGrid
        justify="start"
        className={cn(
            css.paragraphContainer,
            styleClassNames.overflowContainer
        )}
        style={style}
    >
        <FlexboxGrid.Item
            colspan={24}
            className={styleClassNames.scrollableContainer}
        >
            <div className={css.titleContainer}>{title}</div>
            <FlexboxGrid
                justify="center"
                className={styleClassNames.overflowContainer}
            >
                <FlexboxGrid.Item
                    colspan={22}
                    className={styleClassNames.scrollableContainer}
                >
                    <FlexboxGrid
                        className={styleClassNames.scrollableContainer}
                    >
                        <FlexboxGrid.Item
                            colspan={childContainerColspan}
                            className={styleClassNames.scrollableContainer}
                        >
                            {children}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </FlexboxGrid.Item>
    </FlexboxGrid>
);

export default Paragraph;

import {
    SET_ACCOUNT_INFO,
    SET_DIRECTORY_CUSTOMER,
    SET_DIRECTORY_GUEST_TENANT,
    ISetAccountInfo,
    ISetDirectoryCustomer,
    ISetDirectoryGuestTenant,
    IAccountInfo,
    IEntity,
    IGuestTenant,
} from "./account-info-types";

export const setAccountInfo = (accountInfo: IAccountInfo): ISetAccountInfo => ({
    type: SET_ACCOUNT_INFO,
    accountInfo,
});

export const setDirectoryCustomer = (
    customer: IEntity
): ISetDirectoryCustomer => ({
    type: SET_DIRECTORY_CUSTOMER,
    customer,
});

export const setDirectoryGuestTenant = (
    guestTenant: IGuestTenant
): ISetDirectoryGuestTenant => ({
    type: SET_DIRECTORY_GUEST_TENANT,
    guestTenant,
});

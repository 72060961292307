export const SET_ACCOUNT_INFO = "accountInfoReducer/SET_ACCOUNT_INFO";
export const SET_DIRECTORY_CUSTOMER =
    "accountInfoReducer/SET_DIRECTORY_CUSTOMER";
export const SET_DIRECTORY_GUEST_TENANT =
    "accountInfoReducer/SET_DIRECTORY_GUEST_TENANT";

export interface IAccountInfo {
    isAdministrator: boolean;
    tenantId: string;
    userName: string;
    name: string;
}

export interface IEntityDisplay {
    id: string;
    customerName: string | null;
}

export interface IGuestTenantDisplay extends IEntityDisplay {
    tenantName: string;
}

export interface IGuestTenant extends IGuestTenantDisplay {
    isLoaded: boolean;
    hasDirectoryId: boolean;
}

export interface IEntity extends IEntityDisplay {
    isLoaded: boolean;
    hasDirectoryId: boolean;
}

export interface IState {
    accountInfo: IAccountInfo | null;
    customer: IEntity | null;
    guestTenant: IGuestTenant | null;
}

export interface ISetAccountInfo {
    type: typeof SET_ACCOUNT_INFO;
    accountInfo: IAccountInfo;
}

export interface ISetDirectoryCustomer {
    type: typeof SET_DIRECTORY_CUSTOMER;
    customer: IEntity;
}

export interface ISetDirectoryGuestTenant {
    type: typeof SET_DIRECTORY_GUEST_TENANT;
    guestTenant: IGuestTenant;
}

export type Actions =
    | ISetAccountInfo
    | ISetDirectoryCustomer
    | ISetDirectoryGuestTenant;

import React from "react";
import { appVersionDefault } from "../../../utils/constants";
import css from "./AppVersion.module.css";

const isVersionDefined = (versionNumber: string | undefined) =>
    typeof versionNumber !== "undefined";

const AppVersion: React.FC = () => {
    const appVersion =
        isVersionDefined(process.env.REACT_APP_MAJOR) &&
        isVersionDefined(process.env.REACT_APP_MINOR) &&
        isVersionDefined(process.env.REACT_APP_PATCH) &&
        isVersionDefined(process.env.REACT_APP_BUILD_NUMBER)
            ? `v.${process.env.REACT_APP_MAJOR}.${process.env.REACT_APP_MINOR}.${process.env.REACT_APP_PATCH}.${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_ENV_NAME})`
            : appVersionDefault;

    return <div className={css.versionContainer}>{appVersion}</div>;
};

export default AppVersion;

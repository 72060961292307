import {
    SET_ACCOUNT_INFO,
    SET_DIRECTORY_CUSTOMER,
    SET_DIRECTORY_GUEST_TENANT,
    IState,
    Actions,
} from "./account-info-types";
import update from "immutability-helper";

const initialState: IState = {
    accountInfo: null,
    customer: null,
    guestTenant: null,
};

const accountInfoReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_ACCOUNT_INFO:
            return update(state, {
                accountInfo: { $set: action.accountInfo },
            });
        case SET_DIRECTORY_CUSTOMER:
            return update(state, {
                customer: { $set: action.customer },
            });
        case SET_DIRECTORY_GUEST_TENANT:
            return update(state, {
                guestTenant: { $set: action.guestTenant },
            });
        default:
            return state;
    }
};

export default accountInfoReducer;

import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import { getSortedConsentsSelector } from "../../redux/consents/consents-selectors";
import ConsentList from "./ConsentList";

const mapStateToProps = (state: RootState) => ({
    consents: getSortedConsentsSelector(state),
});
const ConsentListContainer = connect(mapStateToProps)(ConsentList);

export default ConsentListContainer;

import i18n from "i18next";
import {
    getCustomerInfoByDirectoryId,
    updateCustomerDirectoryId,
} from "../../api/consent-management";
import {
    getGuestTenantInfoByDirectoryId,
    updateGuestTenantDirectoryId,
} from "../../api/guest-tenant-consent-management";
import {
    responseStatusCodes,
    successfulResponseStatusCodes,
} from "../../utils/constants";
import { obtainErrorMessage } from "../../utils/error-message-provider";
import { toggleLoadingSpinner } from "../loading-spinner/loading-spinner-actions";
import { setErrorMessage } from "../errors/errors-actions";
import { AppThunk } from "../store";
import {
    setDirectoryCustomer,
    setDirectoryGuestTenant,
} from "../account-info/account-info-actions";

export const getCustomerByDirectoryId =
    (directoryId: string): AppThunk =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data, statusCode } = await getCustomerInfoByDirectoryId(
            directoryId
        );
        if (successfulResponseStatusCodes.includes(statusCode)) {
            const hasDirectoryId = data ? true : false;
            dispatch(
                setDirectoryCustomer({
                    id: data?.id ?? "",
                    isLoaded: true,
                    hasDirectoryId: hasDirectoryId,
                    customerName: data?.customerName ?? "",
                })
            );
        } else {
            dispatch(
                setErrorMessage(
                    obtainErrorMessage(JSON.stringify(data), statusCode)
                )
            );
        }
        dispatch(toggleLoadingSpinner(false));
    };

export const getGuestTenantByDirectoryId =
    (
        directoryId: string,
        customerId: string,
        guestTenantId: string
    ): AppThunk =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data, statusCode } = await getGuestTenantInfoByDirectoryId(
            directoryId,
            customerId,
            guestTenantId
        );
        if (successfulResponseStatusCodes.includes(statusCode) && data) {
            const hasDirectoryId = data ? true : false;
            dispatch(
                setDirectoryGuestTenant({
                    id: data?.id ?? "",
                    isLoaded: true,
                    hasDirectoryId: hasDirectoryId,
                    customerName: data.customerName,
                    tenantName: data.tenantName,
                })
            );
        } else if (
            successfulResponseStatusCodes.includes(statusCode) &&
            !data
        ) {
            dispatch(
                setDirectoryGuestTenant({
                    id: "",
                    isLoaded: true,
                    hasDirectoryId: false,
                    customerName: "",
                    tenantName: "",
                })
            );
        } else {
            dispatch(
                setErrorMessage(
                    obtainErrorMessage(JSON.stringify(data), statusCode)
                )
            );
        }
        dispatch(toggleLoadingSpinner(false));
    };

export const updateCustomerDirectory =
    (
        customerId: string,
        directoryId: string,
        accountName: string,
        customerName: string | null
    ): AppThunk<Promise<void>> =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data: hasDirectoryId, statusCode } =
            await updateCustomerDirectoryId(
                customerId,
                directoryId,
                accountName
            );
        if (successfulResponseStatusCodes.includes(statusCode)) {
            dispatch(
                setDirectoryCustomer({
                    id: customerId,
                    isLoaded: true,
                    hasDirectoryId: hasDirectoryId,
                    customerName: customerName,
                })
            );
            if (!hasDirectoryId) {
                dispatch(
                    setErrorMessage(i18n.t("shared.errorMessages.noCustomer"))
                );
            }
        }
        dispatch(toggleLoadingSpinner(false));
    };

export const updateGuestTenantDirectory =
    (
        guestTenantId: string,
        directoryId: string,
        accountName: string,
        customerName: string,
        tenantName: string
    ): AppThunk<Promise<number>> =>
    async (dispatch) => {
        dispatch(toggleLoadingSpinner(true));
        dispatch(setErrorMessage(""));
        const { data: result, statusCode } = await updateGuestTenantDirectoryId(
            guestTenantId,
            directoryId,
            accountName
        );
        if (successfulResponseStatusCodes.includes(statusCode)) {
            dispatch(
                setDirectoryGuestTenant({
                    id: guestTenantId,
                    isLoaded: true,
                    hasDirectoryId: result,
                    customerName: customerName,
                    tenantName: tenantName,
                })
            );
            if (!result) {
                dispatch(
                    setErrorMessage(
                        i18n.t("shared.errorMessages.noGuestTenant")
                    )
                );
            }
        } else if (statusCode == responseStatusCodes.BadRequest) {
            dispatch(
                setErrorMessage(
                    obtainErrorMessage(JSON.stringify(result), statusCode)
                )
            );
        }
        dispatch(toggleLoadingSpinner(false));
        return statusCode;
    };

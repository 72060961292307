import React, { useEffect } from "react";
import {
    useMsalAuthentication,
    AuthenticatedTemplate,
    useMsal,
    useAccount,
    AccountIdentifiers,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { ContainerProps } from "rsuite";
import { loginRedirectRequest } from "../../utils/auth-provider";
import { IAccountInfo } from "../../redux/account-info/account-info-types";
import { azureADRoleTemplateIds } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { CustomerType } from "../../redux/customer/customer-types";
import { ConsentAssociationType } from "../../redux/consents/consents-types";

interface IComponentProps extends ContainerProps {
    accountInfo: IAccountInfo | null;
    directoryCustomerId: string;
    consentCustomerId: string;
    guestTenantId: string;
    isDirectoryCustomerIdLoaded: boolean;
    hasCustomerDirectoryId: boolean;
    customerType: CustomerType | null;
    isDirectoryGuestTenantIdLoaded: boolean;
    consentAssociationType: ConsentAssociationType | null;
    setAccountInfo: (accountInfo: IAccountInfo) => void;
    getTypeByCustomerId: (customerId: string) => void;
    getCustomerByDirectoryId: (directoryId: string) => void;
    getGuestTenantByDirectoryId: (
        directoryId: string,
        customerId: string,
        guestTenantId: string
    ) => void;
    setErrorMessage: (errorMessage: string) => void;
    isCustomerBoundToAnyDirectory: (customerId: string) => Promise<boolean>;
}

const Authentication: React.FC<IComponentProps> = ({
    accountInfo,
    directoryCustomerId,
    consentCustomerId,
    guestTenantId,
    isDirectoryCustomerIdLoaded,
    hasCustomerDirectoryId,
    customerType,
    isDirectoryGuestTenantIdLoaded,
    consentAssociationType,
    setAccountInfo,
    getTypeByCustomerId,
    getCustomerByDirectoryId,
    getGuestTenantByDirectoryId,
    setErrorMessage,
    isCustomerBoundToAnyDirectory,
    children,
}: IComponentProps) => {
    const { t } = useTranslation();
    useMsalAuthentication(InteractionType.Redirect, loginRedirectRequest);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || ({} as AccountIdentifiers));

    useEffect(() => {
        if (accountInfo === null && account !== null) {
            const roleTemplateIds =
                (account?.idTokenClaims as { wids: string[] }).wids || [];
            setAccountInfo({
                isAdministrator: roleTemplateIds.some((roleTemplateId) =>
                    [
                        azureADRoleTemplateIds.applicationAdministrator,
                        azureADRoleTemplateIds.cloudApplicationAdministrator,
                        azureADRoleTemplateIds.globalAdministrator,
                    ].includes(roleTemplateId)
                ),
                tenantId: account.tenantId,
                userName: account.username,
                name: account.name ?? "",
            });
        }
    }, [account, accountInfo]);
    useEffect(() => {
        if (!isDirectoryCustomerIdLoaded && accountInfo) {
            getCustomerByDirectoryId(accountInfo.tenantId);
        }
    }, [isDirectoryCustomerIdLoaded, accountInfo]);
    useEffect(() => {
        if (
            (isDirectoryCustomerIdLoaded &&
                !directoryCustomerId &&
                !consentCustomerId &&
                accountInfo?.isAdministrator) ||
            customerType === CustomerType.Empty
        ) {
            setErrorMessage(t("shared.errorMessages.noCustomer"));
        }
    }, [
        isDirectoryCustomerIdLoaded,
        directoryCustomerId,
        accountInfo,
        customerType,
    ]);
    useEffect(() => {
        if (
            consentAssociationType === ConsentAssociationType.Customer &&
            consentCustomerId &&
            accountInfo?.tenantId &&
            isDirectoryCustomerIdLoaded &&
            !hasCustomerDirectoryId &&
            customerType === CustomerType.D365
        ) {
            isCustomerBoundToAnyDirectory(consentCustomerId).then((isBound) => {
                if (isBound) {
                    setErrorMessage(t("shared.errorMessages.noCustomer"));
                }
            });
        }
    }, [
        consentAssociationType,
        consentCustomerId,
        accountInfo,
        isDirectoryCustomerIdLoaded,
        hasCustomerDirectoryId,
        customerType,
    ]);
    useEffect(() => {
        if (
            consentAssociationType === ConsentAssociationType.Customer &&
            accountInfo &&
            consentCustomerId &&
            isDirectoryCustomerIdLoaded
        ) {
            getTypeByCustomerId(consentCustomerId);
        }
    }, [
        accountInfo,
        consentCustomerId,
        isDirectoryCustomerIdLoaded,
        consentAssociationType,
    ]);
    useEffect(() => {
        if (
            accountInfo &&
            consentAssociationType === ConsentAssociationType.GuestTenant &&
            isDirectoryCustomerIdLoaded &&
            !isDirectoryGuestTenantIdLoaded &&
            consentCustomerId
        ) {
            getGuestTenantByDirectoryId(
                accountInfo.tenantId,
                consentCustomerId,
                guestTenantId
            );
        }
    }, [
        accountInfo,
        consentAssociationType,
        isDirectoryCustomerIdLoaded,
        isDirectoryGuestTenantIdLoaded,
    ]);
    return isDirectoryCustomerIdLoaded && accountInfo ? (
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    ) : null;
};

export default Authentication;

import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import { setCustomerId } from "../../../redux/customer/customer-actions";
import CustomerQueryRoute from "./CustomerQueryRoute";
import { getCustomerId } from "../../../redux/customer/customer-selectors";
import { getGuestTenantId } from "../../../redux/guest-tenants/guest-tenant-selectors";
import { setGuestTenantId } from "../../../redux/guest-tenants/guest-tenant-actions";
import { setConsentAssociationType } from "../../../redux/consents/consents-actions";
import {
    getDirectoryCustomerId,
    getDirectoryGuestTenantId,
    getIsDirectoryGuestTenantIdLoaded,
} from "../../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    directoryCustomerId: getDirectoryCustomerId(state),
    consentCustomerId: getCustomerId(state),
    directoryGuestTenantId: getDirectoryGuestTenantId(state),
    consentGuestTenantId: getGuestTenantId(state),
    isDirectoryGuestTenantIdLoaded: getIsDirectoryGuestTenantIdLoaded(state),
});
const CustomerQueryRouteContainer = connect(mapStateToProps, {
    setConsentCustomerId: setCustomerId,
    setGuestTenantId,
    setConsentAssociationType,
})(CustomerQueryRoute);

export default CustomerQueryRouteContainer;

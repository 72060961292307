import { RootState } from "../store";
import { IAccountInfo, IGuestTenant } from "./account-info-types";

export const getAccountInfo = (state: RootState): IAccountInfo | null =>
    state.accountInfoReducer.accountInfo;

export const getAccountInfoTenantId = (state: RootState): string =>
    getAccountInfo(state)?.tenantId || "";

export const getAccountInfoName = (state: RootState): string =>
    getAccountInfo(state)?.name || "";

export const getAccountInfoUserName = (state: RootState): string =>
    getAccountInfo(state)?.userName || "";

export const getAccountInfoIsAdministrator = (state: RootState): boolean =>
    getAccountInfo(state)?.isAdministrator || false;

export const getDirectoryCustomerId = (state: RootState): string =>
    state.accountInfoReducer.customer?.id || "";

export const getIsDirectoryCustomerIdLoaded = (state: RootState): boolean =>
    state.accountInfoReducer.customer?.isLoaded || false;

export const getHasCustomerDirectoryId = (state: RootState): boolean =>
    state.accountInfoReducer.customer?.hasDirectoryId || false;

export const getDirectoryGuestTenantId = (state: RootState): string =>
    state.accountInfoReducer.guestTenant?.id || "";

export const getIsDirectoryGuestTenantIdLoaded = (state: RootState): boolean =>
    state.accountInfoReducer.guestTenant?.isLoaded || false;

export const getHasGuestTenantDirectoryId = (state: RootState): boolean =>
    state.accountInfoReducer.guestTenant?.hasDirectoryId || false;

export const getCustomerName = (state: RootState): string | null =>
    state.accountInfoReducer.customer?.customerName || null;

export const getGuestTenant = (state: RootState): IGuestTenant | null =>
    state.accountInfoReducer.guestTenant || null;

import React from "react";
import {
    IConsent,
    ConsentStatusType,
} from "../../../redux/consents/consents-types";
import { ContainerProps, FlexboxGrid } from "rsuite";
import ListItemImage from "../ListItemImage/ListItemImage";
import AdminConsentLinkContainer from "../AdminConsentLink/AdminConsentLinkContainer";
import css from "./ListItem.module.css";
import { useTranslation } from "react-i18next";
interface IComponentProps {
    consent: IConsent;
    isLinkEnabled: boolean;
}

const ConsentListItem: React.FC<IComponentProps> = ({
    consent,
    isLinkEnabled,
}: IComponentProps) => {
    const { t } = useTranslation();

    const AdminConsentLinkContainerWrapper = ({ children }: ContainerProps) => (
        <AdminConsentLinkContainer
            consentId={consent.id}
            clientId={consent.clientId}
            isEnabled={isLinkEnabled}
        >
            {children}
        </AdminConsentLinkContainer>
    );
    return (
        <FlexboxGrid align={"top"} className={css.listItemContainer}>
            <FlexboxGrid.Item>
                <AdminConsentLinkContainerWrapper>
                    <ListItemImage status={consent.status.type} />
                </AdminConsentLinkContainerWrapper>
            </FlexboxGrid.Item>
            <div>
                <div className={css.consentLinkContainer}>
                    <AdminConsentLinkContainerWrapper>
                        {consent.templatedName}
                    </AdminConsentLinkContainerWrapper>
                </div>
                {consent.status.type === ConsentStatusType.Rejected &&
                    consent.status.message && (
                        <>
                            <div>
                                {t(
                                    "components.consentList.listItem.statusMessageTitle"
                                )}
                            </div>
                            <div>{consent.status.message}</div>
                        </>
                    )}
            </div>
        </FlexboxGrid>
    );
};

export default ConsentListItem;

import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import Instructions from "./Instructions";
import {
    getAccountInfoIsAdministrator,
    getAccountInfoName,
} from "../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    isAdministratorAccount: getAccountInfoIsAdministrator(state),
    accountName: getAccountInfoName(state),
});
const InstructionsContainer = connect(mapStateToProps)(Instructions);

export default InstructionsContainer;

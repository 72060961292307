import React from "react";
import SeekingDog from "../../../assets/images/seeking-dog.svg";
import ConfusedDog from "../../../assets/images/confused-dog.svg";
import BaseImage from "../BaseImage/BaseImage";

interface IComponentProps {
    isConfused: boolean;
}

const DogImage: React.FC<IComponentProps> = ({
    isConfused,
}: IComponentProps) => (
    <BaseImage
        imageSource={isConfused ? ConfusedDog : SeekingDog}
        style={{ bottom: "18px" }}
    />
);

export default DogImage;

import { RootState } from "../../../redux/store";
import { connect } from "react-redux";
import FourthPage from "./FourthPage";
import { getAccountInfoUserName } from "../../../redux/account-info/account-info-selectors";

const mapStateToProps = (state: RootState) => ({
    accountUserName: getAccountInfoUserName(state),
});
const FourthPageContainer = connect(mapStateToProps)(FourthPage);

export default FourthPageContainer;

export const TOGGLE_LOADING_SPINNER =
    "loadingSpinnerReducer/TOGGLE_LOADING_SPINNER";

export interface IState {
    count: number;
}

export interface IToggleLoadingSpinner {
    type: typeof TOGGLE_LOADING_SPINNER;
    isVisible: boolean;
}

export type Actions = IToggleLoadingSpinner;

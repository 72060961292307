import { Action, applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware, { ThunkAction } from "redux-thunk";
import consentsReducer from "./consents/consents-reducer";
import accountInfoReducer from "./account-info/account-info-reducer";
import customerReducer from "./customer/customer-reducer";
import loadingSpinnerReducer from "./loading-spinner/loading-spinner-reducer";
import errorsReducer from "./../redux/errors/errors-reduсer";
import guestTenantReducer from "./guest-tenants/guest-tenant-reducer";

const rootReducer = combineReducers({
    consentsReducer,
    accountInfoReducer,
    customerReducer,
    loadingSpinnerReducer,
    errorsReducer,
    guestTenantReducer,
});
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export default store;

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { routePath } from "../../utils/routes";
import ConsentResponseContainer from "../../pages/ConsentResponse/ConsentResponseContainer";
import ControlAppRedirectContainer from "../../pages/ControlAppRedirect/ControlAppRedirectContainer";
import InstructionsContainer from "../../pages/Instructions/InstructionsContainer";
import WizardContainer from "../../pages/Wizard/WizardContainer";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import CustomerQueryRouteContainer from "./CustomerQueryRoute/CustomerQueryRouteContainer";

interface IComponentProps {
    isAdministratorAccount: boolean;
}

const Router: React.FC<IComponentProps> = ({
    isAdministratorAccount,
}: IComponentProps) => (
    <Switch>
        <Route exact path={routePath.root}>
            <CustomerQueryRouteContainer>
                <InstructionsContainer />
            </CustomerQueryRouteContainer>
        </Route>
        <ProtectedRoute
            isAllowed={isAdministratorAccount}
            exact
            path={routePath.wizard}
        >
            <CustomerQueryRouteContainer>
                <WizardContainer />
            </CustomerQueryRouteContainer>
        </ProtectedRoute>
        <ProtectedRoute
            isAllowed={isAdministratorAccount}
            exact
            path={routePath.consentResponse}
        >
            <ConsentResponseContainer />
        </ProtectedRoute>
        <Route
            exact
            path={routePath.controlAppRedirect}
            component={ControlAppRedirectContainer}
        />
        <Redirect to={routePath.root} />
    </Switch>
);

export default Router;

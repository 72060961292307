import {
    SET_CUSTOMER_ID,
    SET_CUSTOMER_TYPE,
    CustomerType,
    ISetCustomerId,
    ISetCustomerType,
} from "./customer-types";

export const setCustomerId = (customerId: string): ISetCustomerId => ({
    type: SET_CUSTOMER_ID,
    customerId,
});

export const setCustomerType = (
    customerType: CustomerType | null
): ISetCustomerType => ({
    type: SET_CUSTOMER_TYPE,
    customerType,
});

import React from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "../../../components/Paragraph/Paragraph";
import InstructionImage from "../../../components/InstructionImage/InstructionImage";
import { template } from "lodash";
import parse from "html-react-parser";
import css from "./FirstPage.module.css";
import stepImage from "../../../assets/images/instructionSteps/1.jpg";

interface IComponentProps {
    accountName: string;
}

const FirstPage: React.FC<IComponentProps> = ({
    accountName,
}: IComponentProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Paragraph
                style={{ marginBottom: "10px" }}
                title={template(
                    t(
                        "pages.instructions.firstPage.firstParagraphTitleTemplate"
                    )
                )({
                    userName: accountName,
                })}
            >
                {parse(
                    t(
                        "pages.instructions.firstPage.firstParagraphDescriptionTemplate"
                    )
                )}
            </Paragraph>
            <Paragraph
                title={t("pages.instructions.firstPage.secondParagraphTitle")}
            >
                {t(
                    "pages.instructions.firstPage.secondParagraphFirstPartDescriptionText"
                )}
                <div className={css.dynamicsLinkContainer}>
                    <a
                        href="http://lcs.dynamics.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        http://lcs.dynamics.com/
                    </a>
                </div>
                <>
                    {parse(
                        t(
                            "pages.instructions.firstPage.secondParagraphSecondPartDescriptionText"
                        )
                    )}
                </>
                <InstructionImage imageSource={stepImage} imageColspan={13} />
            </Paragraph>
        </>
    );
};

export default FirstPage;
